import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useRef, useEffect, useState } from "react";
import axios from "axios";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import CardActionArea from "@mui/material/CardActionArea";
import "./App.css";
import InfiniteScroll from "react-infinite-scroll-component";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const theme = createTheme();
function Album() {
  const [loading, setLoading] = useState(true);
  const listInnerRef = useRef();
  const [currPage, setCurrPage] = useState(1);
  const [prevPage, setPrevPage] = useState(0);
  const [userList, setUserList] = useState([]);
  const [lastList, setLastList] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `https://api.almashhad.tk/api/v1/instagram/posts?page=${currPage}&limit=18`
      );
      setLoading(false);
      if (!response.data.data.length) {
        setLastList(true);
        setHasMore(false);
        return;
      }
      setPrevPage(currPage);
      setUserList([...userList, ...response.data.data]);
    };
    if (!lastList && prevPage !== currPage) {
      fetchData();
    }
  }, [currPage, userList, lastList, prevPage]);

  function dataFetch() {
    setCurrPage(currPage + 1);
  }

  return (
    <div>
      {!loading ? (
        <InfiniteScroll
          dataLength={userList.length}
          next={dataFetch}
          hasMore={hasMore}
          scrollableTarget="scrollableDiv"
        >
          <div
            id="scrollableDiv"
            ref={listInnerRef}
            style={{ height: "100vh", overflowY: "auto" }}
          >
            <ThemeProvider theme={theme}>
              <CssBaseline />

              <main>
                <Box
                  sx={{
                    bgcolor: "background.paper",
                  }}
                >
                  <Container maxWidth="sm">
                    <Box
                      component="img"
                      sx={{
                        height: "auto",
                        width: "100%",
                      }}
                      alt="Al Mashhad Cover Image"
                      src="/cover.jpg"
                    />
                  </Container>
                </Box>

                <Container sx={{ py: 8 }} maxWidth="md">
                  <Grid container spacing={4} sx={{ width: "auto", ml: 0 }}>
                    {userList.map((card) => (
                      <Grid
                        item
                        key={card.id}
                        xs={6}
                        sm={4}
                        md={4}
                        className="gridItem"
                      >
                        <Card
                          sx={{
                            height: "100%",
                            boxShadow: "none",
                            display: "flex",
                            flexDirection: "column",
                            borderRadius: 0,
                          }}
                        >
                          <CardActionArea href={card.url} target="_blank">
                            <CardMedia
                              component="img"
                              className="imageSize"
                              image={card.image}
                              alt={card.caption}
                            />
                          </CardActionArea>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Container>
              </main>
            </ThemeProvider>
          </div>
        </InfiniteScroll>
      ) : (
        <Backdrop sx={{ color: "#233fd7", background: "#fff" }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </div>
  );
}
export default Album;
